import { Injectable } from '@angular/core'
import { User } from '@tradecafe/types/core'
import { ModalService } from 'src/shared/modal'
import { CompanyContactFormComponent, CompanyContactFormOptions } from './company-contact-form.component'


@Injectable()
export class CompanyContactFormService {
  constructor(private readonly modal: ModalService) {}

  showUpdateContact(options: CompanyContactFormOptions) {
    return this.modal.openDialog<CompanyContactFormComponent, CompanyContactFormOptions, User>(
      CompanyContactFormComponent,
      options,
      {
        panelClass: ['tc-dialog', 'modal-lg'],
        width: null,
        maxWidth: null,
        autoFocus: false,
        disableClose: true,
      }).toPromise()
  }
}
