<form [formGroup]="userForm" (submit)="save()">
  <h2 mat-dialog-title>
    <mat-icon class="fa fa-floppy-disk"></mat-icon>
    {{ title }}
  </h2>
  <div mat-dialog-content>
    <section style="grid-template-columns: minmax(0, 2fr) minmax(0, 5fr) minmax(0, 5fr)">
      <tc-select-search placeholder="Title"
        [ctrl]="userForm.controls.title"
        [items]="titleOptions"
      ></tc-select-search>
      <mat-form-field>
        <mat-label>First name</mat-label>
        <input matInput name="firstname" [formControl]="userForm.controls.firstname">
        @if (checkForErrorsIn(userForm.controls.firstname, 'First name'); as errs) {
          <mat-error>{{errs}}</mat-error>
        }
      </mat-form-field>
      <mat-form-field>
        <mat-label>Last name</mat-label>
        <input matInput name="lastname" [formControl]="userForm.controls.lastname">
        @if (checkForErrorsIn(userForm.controls.lastname, 'Last name'); as errs) {
          <mat-error>{{errs}}</mat-error>
        }
      </mat-form-field>
    </section>
    <section style="grid-template-columns: minmax(0, 1fr) minmax(0, 1fr) minmax(0, 1fr)">
      <mat-form-field>
        <mat-label>Email address</mat-label>
        <input matInput type="email"name="primaryemail" [formControl]="userForm.controls.primaryemail">
        @if (checkForErrorsIn(userForm.controls.primaryemail, 'Email'); as errs) {
          <mat-error>{{errs}}</mat-error>
        }
      </mat-form-field>
      @if (canEditUserRole) {
        <tc-select-search placeholder="Role"
          [ctrl]="userForm.controls.role"
          [items]="availableRoles" bindValue="id" bindLabel="id">
          @if (checkForErrorsIn(userForm.controls.role, 'Role'); as errs) {
            <span class="error-container">{{errs}}</span>
          }
        </tc-select-search>
      }
      @if (canEditUserRole && userForm.controls.role.value === 'junior-administrator') {
        <tc-select-search placeholder="Managed Traders" [multiple]="true"
          [ctrl]="userForm.controls.managedTraders"
          [items]="allTraders$ | async" bindValue="user_id" bindLabel="fullname"
        ></tc-select-search>
      }
      @if (canEditUserRole && userForm.controls.role.value === 'junior-administrator') {
        <tc-select-search placeholder="Managed LCs" [multiple]="true"
          [ctrl]="userForm.controls.managedLogisticCoordinators"
          [items]="allLCs$ | async" bindValue="user_id" bindLabel="fullname"
        ></tc-select-search>
      }
      @if (!canEditUserRole) {
        <mat-form-field>
          <mat-label>Role</mat-label>
          <input matInput readonly [value]="userForm.controls.role.value">
        </mat-form-field>
      }
      <mat-form-field>
        <mat-label>Change Password</mat-label>
        <input matInput [type]="hidePassword ? 'password' : 'text'"
          autocomplete="new-password" name="changePassword"
          [disabled]="!isAdminManagerSuperuser && !isJuniorAdmin"
          [formControl]="changePasswordCtrl">
        <button mat-icon-button matSuffix class="tc-toggle-password"
          (click)="hidePassword = !hidePassword"
          [disabled]="!isAdminManagerSuperuser && !isJuniorAdmin">
          <mat-icon class="fa"
            [class.fa-eye]="hidePassword"
            [class.fa-eye-slash]="!hidePassword"
          ></mat-icon>
        </button>
        @if (checkForErrorsIn(changePasswordCtrl, 'Password'); as errs) {
          <mat-error>{{errs}}</mat-error>
        }
        @if (changePasswordCtrl.valid && changePasswordCtrl.dirty && changePasswordCtrl.value) {
          <mat-hint>Password will be changed.</mat-hint>
        }
      </mat-form-field>
    </section>
    <section style="grid-template-columns: minmax(0, 1fr) minmax(0, 1fr)">
      <mat-form-field>
        <mat-label>Address</mat-label>
        <input matInput [formControl]="userForm.controls.addressStreet1">
      </mat-form-field>
      <tc-select-search placeholder="Country"
        [ctrl]="userForm.controls.addressCc"
        [items]="countries$ | async" bindValue="code" bindLabel="name"
      ></tc-select-search>
      <mat-form-field>
        <mat-label>Province/State</mat-label>
        <input matInput [formControl]="userForm.controls.addressState">
      </mat-form-field>
      <mat-form-field>
        <mat-label>City</mat-label>
        <input matInput [formControl]="userForm.controls.addressCity">
      </mat-form-field>
      <mat-form-field>
        <mat-label>Zip code</mat-label>
        <input matInput [formControl]="userForm.controls.addressPostal">
      </mat-form-field>
      <mat-checkbox [formControl]="userForm.controls.designated">
        Designated TradeCafe contact
      </mat-checkbox>
    </section>
    <section style="grid-template-columns: minmax(0, 1fr) minmax(0, 1fr)">
      <div style="display: grid; gap: 0 15px; grid-template-columns: minmax(0, 1fr) minmax(0, 2fr) minmax(0, 1fr)">
        <tc-select-search placeholder="Code" name="phoneCode"
          [ctrl]="userForm.controls.phoneCode"
          [items]="phoneCodes" bindValue="code" bindLabel="name" bindHint="hint"
          [searchFn]="phoneCodesSearch">
          @if (checkForErrorsIn(userForm.controls.phoneCode, 'Country Code'); as errs) {
            <span class="error-container">{{errs}}</span>
          }
        </tc-select-search>
        <mat-form-field>
          <mat-label>Telephone</mat-label>
          <input matInput name="primaryphone" [formControl]="userForm.controls.phoneNumber">
          @if (checkForErrorsIn(userForm.controls.phoneNumber, 'Telephone'); as errs) {
            <mat-error>{{errs}}</mat-error>
          }
        </mat-form-field>
        <mat-form-field>
          <mat-label>Ext</mat-label>
          <input matInput [formControl]="userForm.controls.phone_ext">
        </mat-form-field>
        <mat-form-field style="grid-column: span 2">
          <mat-label>Fax</mat-label>
          <input matInput [formControl]="userForm.controls.fax">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Ext</mat-label>
          <input matInput [formControl]="userForm.controls.fax_ext">
        </mat-form-field>
      </div>
      <div>
        <mat-checkbox [formControl]="userForm.controls.agent">
          Company agent
        </mat-checkbox>
        <ul>
          <li>Company agents do not receive Shipping Log emails</li>
          <li>Company agents are listed for selection contacts for deals and cost sheet</li>
        </ul>
      </div>
      <div style="display: grid; gap: 0 15px; grid-template-columns: minmax(0, 1fr) minmax(0, 2fr) minmax(0, 1fr)">
        <tc-select-search placeholder="Code" name="cellCode"
          [ctrl]="userForm.controls.cellCode"
          [items]="phoneCodes" bindValue="code" bindLabel="name" bindHint="hint"
          [searchFn]="phoneCodesSearch">
          @if (checkForErrorsIn(userForm.controls.cellCode, 'Country Code'); as errs) {
            <span class="error-container">{{errs}}</span>
          }
        </tc-select-search>
        <mat-form-field>
          <mat-label>Cell #</mat-label>
          <input matInput name="cell_phone" [formControl]="userForm.controls.cellNumber">
          @if (checkForErrorsIn(userForm.controls.cellNumber, 'Cell #'); as errs) {
            <mat-error>{{errs}}</mat-error>
          }
        </mat-form-field>
        <mat-checkbox class="tc-phone-checkbox"
          [checked]="userForm.controls.phoneNumber.value && userForm.controls.cellNumber.value === userForm.controls.phoneNumber.value"
          (change)="updateCellNumber($event)">
          Same as Telephone number above
        </mat-checkbox>
        <tc-select-search placeholder="Code" name="whatsAppCode"
          [ctrl]="userForm.controls.whatsAppCode"
          [items]="phoneCodes" bindValue="code" bindLabel="name" bindHint="hint"
          [searchFn]="phoneCodesSearch">
          <span class="error-container" *ngIf="checkForErrorsIn(userForm.controls.whatsAppCode, 'Country Code') as errs">{{errs}}</span>
        </tc-select-search>
        <mat-form-field>
          <mat-label>WhatsApp #</mat-label>
          <input matInput name="whatsAppNumber" [formControl]="userForm.controls.whatsAppNumber">
          <mat-error *ngIf="checkForErrorsIn(userForm.controls.whatsAppNumber, 'WhatsApp #') as errs">{{errs}}</mat-error>
        </mat-form-field>
        <mat-checkbox class="tc-phone-checkbox"
          [checked]="userForm.controls.cellNumber.value && userForm.controls.cellNumber.value === userForm.controls.whatsAppNumber.value"
          (change)="updateWhatsAppNumber($event)">
          Same as Cell number above
        </mat-checkbox>
      </div>

      <div style="text-align: right">
        @if (!(signatureFile$ | async)) {
          @if (!blobFile) {
            <button mat-button type="button" (click)="inputFile.click()">
              <i class="fa fa-cloud-arrow-up"></i> Upload Signature
            </button>
          }
          <input #inputFile type="file" style="display:none" (change)="inputFileChanged()">
          @if (blobFile) {
            <a [href]="blobFile.blobUrl" target="_blank" [download]="blobFile.name">{{blobFile.name}}</a>
            <button mat-icon-button (click)="removeUserSignatureFile()" aria-label="Remove file">
              <mat-icon class="fa fa-xmark"></mat-icon>
            </button>
          }
        }
        @if (signatureFile$ | async; as signatureFile) {
          <a [href]="signatureFile.link" target="_blank" download>{{signatureFile.name}}</a>
          <button mat-icon-button (click)="removeUserSignatureFile()" aria-label="Remove file">
            <mat-icon class="fa fa-xmark"></mat-icon>
          </button>
        }
      </div>
      <tc-select-search placeholder="Preferred Language" name="language"
        [ctrl]="userForm.controls.language"
        [items]="languageOptions" bindValue="code" bindLabel="name"
      ></tc-select-search>
    </section>
  </div>
  <mat-progress-bar [mode]="(inProgress$ | async) ? 'indeterminate' : undefined"></mat-progress-bar>
  <div mat-dialog-actions>
    <mat-slide-toggle class="tc-allow-login-switch" color="primary"
      [formControl]="userForm.controls.login_allowed"
      [disabled]="(!isAdminManagerSuperuser && !isJuniorAdmin) || userId === currentUserId">
      Login Allowed
    </mat-slide-toggle>
    <mat-slide-toggle class="tc-allow-login-switch" color="primary"
      [formControl]="userForm.controls.allowLoginToAutomatedTrading"
      [disabled]="(!isAdminManagerSuperuser && !isJuniorAdmin) || userId === currentUserId">
      Login to Automated Trading App
    </mat-slide-toggle>
    <span style="flex: 1 1 auto"></span>
    <button mat-button type="button" mat-dialog-close>Cancel</button>
    <button mat-raised-button type="submit" color="primary">
      <i class="fa fa-fw" aria-hidden="true"
        [class.fa-save]="(inProgress$ | async) !== 'save'"
        [class.fa-spin]="(inProgress$ | async) === 'save'"
        [class.fa-spinner]="(inProgress$ | async) === 'save'"
      ></i>
      Save
    </button>
  </div>
</form>
