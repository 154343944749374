import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'

const {apiUrl} = environment


@Injectable()
export class ValidateApiService {
  constructor(private http: HttpClient) { }

  validateEmail(email: string, validate: boolean) {
    return this.http.post<void>(`${apiUrl}/validate/email`, { email, validate })
  }

  validatePhone(phone: string) {
    return this.http.post<void>(`${apiUrl}/validate/phone`, { phone })
  }
}
